.center {
  text-align: center;
  font-size: 25px;
  /* border: 5px solid red; */
  height: 50px;
}
.input {
  height: 25px;
  width: 300px;
  text-align: center;
}

table {
  border-collapse: collapse;
  font-size: 16px;
  font-weight: normal;
  color: #333333;
  margin: auto;
}

th,
td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #dddddd;
  vertical-align: top;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #dddddd;
}

/* .btn-search {
  height: 27.98px;
  width: 50px;
  margin-left: 5px;
} */

.btn-search {
  margin-left: 5px;
  height: 27.98px;
  padding: 6px 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  border-radius: 6px;
  border: none;
  padding-top: 5px;

  color: #fff;
  background: linear-gradient(180deg, #4b91f7 0%, #367af6 100%);
  background-origin: border-box;
  box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25),
    inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btn-search:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2),
    0px 0.5px 1.5px rgba(54, 122, 246, 0.25),
    0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}
.btn-search:hover {
  opacity: 50%;
}
