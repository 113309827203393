.title-padding {
  padding-top: 50px;
  padding-bottom: 75px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 20px;
  margin: 0 auto;
  width: 95%;
}

.prop-img {
  align-items: center;
  width: 50%;
  height: 50%;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5em 1em;
  border: 1px solid #e1e1e1;
  border-radius: 0.25em;
}

.img-portfolio {
  width: 60%;
  height: 80%;
  margin: auto;
}

.btn {
  font-size: 0.85em;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.75em 1.5em;
  background-color: #278da4;
  border-radius: 0.35em;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  margin-top: auto;
}

.btn:hover {
  background-color: rgba(39, 141, 164, 0.8);
}

h3 {
  padding-bottom: 15px;
}

h2 {
  text-align: center;
}

div p {
  color: black;
  font-size: 1rem;
  padding-top: 25px;
  padding-bottom: 10px;
  text-align: center;
  font-family: "Raleway", sans-serif;
}
