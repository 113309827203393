.title-padding-react {
  padding-top: 50px;
  padding-bottom: 75px;
}

.grid-react {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 20px;
  margin: 0 auto;
  width: 95%;
}

/*
If i add multiple projects I may need to add this back in
.prop-img-react {
  width: 50%;
  height: 50%;
} */

.card-react {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5em 1em;
  border: 1px solid #e1e1e1;
  border-radius: 0.25em;
}

.btn-react {
  font-size: 0.85em;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.75em 1.5em;
  background-color: #278da4;
  border-radius: 0.35em;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  margin-top: auto;
}

.btn-react:hover {
  background-color: rgba(39, 141, 164, 0.8);
}
